import * as React from 'react';
import { NavLink, NavLinkRenderProps } from "react-router-dom";

type AppNavLink = {
    text: string,
    path: string
}

const AppNavLinks: AppNavLink[] = [
    { text: "Home", path: "/" },
    { text: "Login", path: "/login" }
]

export function AppNav(): JSX.Element {
    function isActiveTab ({ isActive }: NavLinkRenderProps): string {
        return isActive ? "navlink-active-background link-text" : "navlink-background link-text";
    }

    return (
        <div className="flex justify-between gap-1">
            {AppNavLinks.map((link: AppNavLink, index: number): JSX.Element => {
                return (
                    <NavLink key={index} to={link.path} className={isActiveTab}>
                        <div className="ma-2">{link.text}</div>
                    </NavLink>
                );
            })}
        </div>
    );
}