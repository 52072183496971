import * as React from 'react';
import { AppNav } from './AppNav'
import { NavLink } from "react-router-dom";

export function AppHeader(): JSX.Element {
    return (
        <header className="flex justify-around primary-light-bottom-border-strong dark-grey-background">
            <AppTitle />
            <AppNav />
        </header>
    );
}

function AppTitle(): JSX.Element {
    return (
        <NavLink to="/" className="link-text">
            <h4 className="white-text ma-1 ml-2">Tsundraki Studio</h4>
        </NavLink>
    );
}