import * as React from 'react';
import './style';
import { AppRouter } from './AppRouter';
import { AppHeader } from './AppHeader';

export default function App(): JSX.Element {
  return (
    <div className="flex-vertical flex-fill dark-grey-background">
      <AppHeader />
      <AppRouter />
    </div>
  );
}