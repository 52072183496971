import * as React from 'react';

export function HomePage(): JSX.Element {
    return (
        <div className="flex-vertical ml-1 mr-1 gap-1">
            <h2 className="white-text">Home</h2>

            <div className="basic-box">
                <p>text</p>
            </div>
        </div>
    );
}