import * as React from "react";

export function NotFound(): JSX.Element {
    return (
        <div className="flex-vertical text-align-center ma-2 gap-2">
            <h2 className="white-text">404 - Page Not Found</h2>

            <div className="basic-box flex-vertical">
                <p className="mb-0">
                    If this error persists, please contact the web admin:
                </p>
                <a href="mailto:tsundraki@gmail.com">tsundraki@gmail.com</a>
            </div>
        </div>
    );
}